import Vue from 'vue'
import lodash_sum from "lodash/sum";
import axios from 'axios';

const assetUrl = process.env.MIX_VAPOR_ASSET_URL
    ? process.env.MIX_VAPOR_ASSET_URL
    : '';

class Vapor {
    /**
     * Generate the S3 URL to an application asset.
     */
    asset(path) {
        return assetUrl + '/' + path;
    }

    /**
     * Store a file in S3 and return its UUID, key, and other information.
     */
    async store(file, mutation, mutationName, apollo, progress) {
        let response = await apollo.mutate({
                mutation: mutation,
                variables: {
                    fileName: file.name,
                },
            },
        ).then(({data}) => {
            return data[mutationName];
        });

        if (typeof progress === 'undefined') {
            progress = () => {
            };
        }

        let formInputs = JSON.parse(response.inputs);
        let formData = new FormData();
        Object.keys(formInputs).forEach(key => formData.append(key, formInputs[key]));
        formData.append("file", file);

        await axios.post(response.url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                progress(progressEvent.loaded / progressEvent.total);
            }
        })

        return response.uuid;
    }

    async storeMultipart(file, mutation, mutationName, apollo, progressCallback) {
        const chunkSize = 1024 * 1024 * 1024 //1GB
        const size = file.size
        const partCount = Math.ceil(size / chunkSize)

        let parts = {}
        for (let i = 0; i < partCount; i++) {
            const start = i * chunkSize
            const end = (i + 1) * chunkSize
            if (i < partCount) {
                parts[i + 1] = file.slice(start, end)
            } else {
                parts[i + 1] = file.slice(start)
            }
        }

        let response = await apollo.mutate({
                mutation: mutation,
                variables: {
                    fileName: file.name,
                    parts: partCount,
                },
            },
        ).then(({data}) => {
            return data[mutationName];
        });
        const promises = []
        let loaded = []
        for (const uploadPart of response.parts) {
            const blob = parts[uploadPart.part]
            const url = uploadPart.url
            promises.push(axios.put(url, blob, {
                onUploadProgress: (e) => {
                    loaded[uploadPart.part] = e.loaded
                    const progress = lodash_sum(loaded)/size
                    progressCallback(progress)
                }
            }))
        }
        let resParts = await Promise.all(promises)
        return {
            uuid: response.uuid,
            uploadId: response.uploadId,
            parts: resParts.map((part, index) => ({
                ETag: JSON.parse(part.headers.etag),
                PartNumber: index + 1
            }))
        }
    }
}

Object.defineProperty(Vue.prototype, '$vapor', {value: new Vapor()})
export default new Vapor()
