import Pusher from "pusher-js";
import Echo from "laravel-echo";
import Vue from "vue";
import axios from "axios";

const echoOptions = {
    broadcaster: 'pusher',
        forceTLS: process.env.VUE_APP_SOCKETI_TLS === "1",
        enabledTransports: process.env.VUE_APP_SOCKETI_TLS === "1" ? ['ws', 'wss'] : ['ws'],
        wsHost: process.env.VUE_APP_SOCKETI_HOST,
        wsPort: process.env.VUE_APP_SOCKETI_PORT,
        wssPort: process.env.VUE_APP_SOCKETI_PORT,
        key: process.env.VUE_APP_SOCKETI_APP_KEY,
        authorizer: (channel) => {
        return {
            authorize: (socketId, callback) => {
                axios.post(process.env.VUE_APP_SOCKETI_AUTH_ENDPOINT, {
                    socket_id: socketId,
                    channel_name: channel.name,
                }, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('api-token')}`,
                    }
                })
                    .then(response => {
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
            }
        };
    },
}

window.Pusher = Pusher
const echo = new Echo(echoOptions);

Object.defineProperty(Vue.prototype, `$echo`, { value: echo })