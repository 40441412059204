import { mapMutations, mapState } from 'vuex';


export default {
    computed: {
        ...mapState( 'errorAlert', { errorAlertActive: 'active', serverErrors: 'serverErrors' } ),
        localErrorAlertActive:
            {
                get ()
                {
                    return this.errorAlertActive;
                },
                set ( active )
                {
                    this.setErrorAlertActive( active );
                },
            },
        localServerErrors:
            {
                get ()
                {
                    return this.serverErrors;
                },
                set ( active )
                {
                    this.setServerErrors( active );
                },
            },
    },
    methods: {
        ...mapMutations( {
            setErrorAlertActive: 'errorAlert/setActive',
            setServerErrors: 'errorAlert/setServerErrors',
        } ),
    },
};