<template>
  <v-dialog fullscreen v-model="dialog">
    <template v-slot:activator="{on}">
      <v-btn color="primary" text v-on="on">Wiki</v-btn>
    </template>
    <v-card>
      <v-toolbar
          dark
          color="primary"
          tile
      >
        <v-toolbar-title>Wiki</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="auto">
            <v-list>
              <v-list-item v-for="(item, idx) in Object.values(wikiData)" @click="page=item.page; panel=0; interacted=false" :key="idx">
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <v-card-title>{{ activeData.title }}</v-card-title>
            <v-card-text>
              <v-expansion-panels v-model="panel">
                <v-expansion-panel v-for="(video, idx) in activeData.videos" :key="idx">
                  <v-expansion-panel-header @click="interacted=true">{{ video.title }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <video controls :src="`${baseUrl}${video.url}.mp4`"
                           :autoplay="interacted"
                           width="100%"
                           style="max-width: 960px;
    margin: 0 auto;
    display: block;"
                           v-if="panel==idx && activeData.page==page"></video>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SuWikiVideos",
  data() {
    return {
      dialog: false,
      interacted: false,
      page: 1,
      baseUrl: 'https://wiki-videos.streamed-up.com/',
      wikiData: {
        1: {
          page: 1,
          title: 'Creator',
          videos: [
            {
              title: 'Creator anlegen',
              url: 'Creator anlegen'
            },
            {
              title: 'Creator bearbeiten',
              url: 'Creator Einstellungen'
            },
          ],
        },
        2: {
          page: 2,
          title: 'Channel',
          videos: [
            {
              title: 'Channel anlegen',
              url: 'Channel anlegen'
            },
            {
              title: 'Channel bearbeiten',
              url: 'Channel bearbeiten'
            },
          ],
        },
        3: {
          page: 3,
          title: 'Landing Page',
          videos: [
            {
              title: 'Landing Page anlegen',
              url: 'Landing Page anlegen'
            },
            {
              title: 'Landing Page bearbeiten',
              url: 'Landing Page bearbeiten'
            },
          ],
        },
        4: {
          page: 4,
          title: 'Featured Content',
          videos: [
            {
              title: 'Featured Content anlegen',
              url: 'Featured Content anlegen'
            },
            {
              title: 'Featured Content bearbeiten',
              url: 'Featured Content bearbeiten'
            },
          ],
        },
        5: {
          page: 5,
          title: 'Playlist',
          videos: [
            {
              title: 'Playlist anlegen',
              url: 'Playlist erstellen'
            },
            {
              title: 'Playlist bearbeiten',
              url: 'Playlist bearbeiten'
            },
          ],
        },
        6: {
          page: 6,
          title: 'Menüeinträge',
          videos: [
            {
              title: 'Menüeintrag anlegen',
              url: 'Menueeintrag an legen'
            },
          ],
        },
        7: {
          page: 7,
          title: 'Sponsoren',
          videos: [
            {
              title: 'Sponsor anlegen',
              url: 'Sponsor anlegen'
            },
            {
              title: 'Sponsor bearbeiten',
              url: 'Sponsor bearbeiten'
            },
          ],
        },
        8: {
          page: 8,
          title: 'Experten',
          videos: [
            {
              title: 'Experten anlegen',
              url: 'Experten anlegen'
            },
            {
              title: 'Experten bearbeiten',
              url: 'Experten bearbeiten'
            },
          ],
        },
        9: {
          page: 9,
          title: 'Benutzer',
          videos: [
            {
              title: 'Benutzer anlegen',
              url: 'Benutzer erstellen'
            },
            {
              title: 'Benutzer bearbeiten',
              url: 'Benutzer bearbeiten'
            },
            {
              title: 'Benutzer validieren',
              url: 'Benutzer validieren'
            },
          ],
        },
        10: {
          page: 10,
          title: 'Sendung',
          videos: [
            {
              title: 'Sendung',
              url: 'Sendung'
            },
          ],
        },
        11: {
          page: 11,
          title: 'Livestream',
          videos: [
            {
              title: 'Livestream anlegen',
              url: 'Livestream anlegen'
            },
            {
              title: 'Livestream vorbereiten',
              url: 'Livestream vorbereiten'
            },
            {
              title: 'Livestream betreuen',
              url: 'Livestream betreuen'
            },
            {
              title: 'Livestream nachbearbeiten',
              url: 'Livestream nachbearbeiten UNVOLLSTAENDIG'
            },
            {
              title: 'Livestream nachbearbeiten 2',
              url: 'Vod aus Livestream erstellt'
            },
          ],
        },
        12: {
          page: 12,
          title: 'Video',
          videos: [
            {
              title: 'Video anlegen',
              url: 'Video anlegen'
            },
            {
              title: 'Video bearbeiten',
              url: 'Video bearbeiten UNVOLLSTAENDIG'
            },
            {
              title: 'Video veröffentlichen',
              url: 'Video veröffentlichen'
            },
          ],
        },
      },
      panel: 0
    }
  },
  computed: {
    activeData() {
      return this.wikiData[this.page]
    }
  }
}
</script>

<style scoped>

</style>