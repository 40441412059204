import { mapMutations, mapState } from 'vuex';

export default {
    methods: {
        ...mapMutations(['apolloState/setApolloLoading', 'apolloState/setApolloLoadingQueriesCount'])
    },
    computed: {
        ...mapState(['apolloState/apolloLoading', 'apolloState/apolloLoadingQueriesCount']),
        busy() {
            return this.loading || this.loadingQueriesCount > 0
        },
        loading: {
            get() {
                return this.apolloLoading
            },
            set(loading) {
                this.setApolloLoading(loading)
            }
        },
        loadingQueriesCount: {
            get() {
                return this.apolloLoadingQueriesCount
            },
            set(loadingQueriesCount) {
                this.setApolloLoadingQueriesCount(loadingQueriesCount)
            }
        }
    }
}
