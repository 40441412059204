<template>
    <v-app>
        <v-app-bar v-if="!$route.meta.hideMenu"
                   app
                   color="white"
                   clipped-left
                   :dense="$vuetify.breakpoint.mobile"
        >
            <v-app-bar-nav-icon large class="mr-3" @click="menu=!menu"></v-app-bar-nav-icon>
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <a :href="frontedUrl" target="_blank" v-on="on"><img height="40px"
                                                                         :src="require('@/assets/su-logo-mini-rgb.png')"
                                                                         alt=""></a>
                </template>
                <span>Zum Nutzer-Frontend</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-text-field
                v-if="!$vuetify.breakpoint.mobile" append-icon="mdi-magnify" placeholder="Suchen..." outlined dense
                hide-details
                :value="searchTerm"
                @input="debouncedSearchTerm"
                @click:append="$router.push(`/search/${searchTerm}`)"
                @keydown.enter="$router.push(`/search/${searchTerm}`)">
            </v-text-field>
            <v-spacer/>
            <v-btn text color="primary" href="https://soerf.atlassian.net/servicedesk/customer/portal/3"
                   target="_blank">
                Ticket
            </v-btn>
            <su-wiki-videos/>
        </v-app-bar>
        <v-navigation-drawer v-if="!$route.meta.hideMenu" app clipped v-model="menu">
            <su-menu-drawer/>
        </v-navigation-drawer>

        <v-main>
            <su-success-alert v-if="!$route.meta.hideMenu"/>
            <su-error-alert v-if="!$route.meta.hideMenu"/>
            <template v-if="!$route.meta.hideMenu && videoUploads">
                <su-vod-upload-alert :id="videoUpload.id" :name="videoUpload.name" :progress="videoUpload.progress"
                                     :video-type="videoUpload.type"
                                     v-for="(videoUpload, idx) in videoUploads" :key="idx"></su-vod-upload-alert>
            </template>
            <template v-if="!$route.meta.hideMenu && me && me.awsTranscodeJobs">
                <su-vod-transcode-alert :id="awsTranscodeJob.transcodeable.id" :name="awsTranscodeJob.transcodeable.name"
                                        :progress="awsTranscodeJob.progress"
                                        :status="awsTranscodeJob.status"
                                        :video-type="getVideoTypeForTranscodeJob(awsTranscodeJob)"
                                        v-for="(awsTranscodeJob, idx) in me.awsTranscodeJobs"
                                        :key="idx"></su-vod-transcode-alert>
            </template>
            <router-view/>

        </v-main>
    </v-app>
</template>

<script>

import SuMenuDrawer from '@/components/menu/MenuDrawer';
import SuSuccessAlert from './components/SuccessAlert';
import SuErrorAlert from './components/ErrorAlert';
import me from '@/graphql/queries/user/me.graphql';
import loadingMixin from "@/mixins/loadingMixin";
import {mapMutations, mapState} from "vuex";
import SuVodUploadAlert from "@/components/vod/VodUploadAlert";
import SuVodTranscodeAlert from "@/components/vod/VodTranscodeAlert";
import SuWikiVideos from "@/components/menu/WikiVideos";
import search from "@/mixins/search";

export default {
    name: 'App',
    components: {SuWikiVideos, SuVodTranscodeAlert, SuVodUploadAlert, SuErrorAlert, SuSuccessAlert, SuMenuDrawer},
    mixins: [loadingMixin, search],
    mounted() {
        let local_url = window.location,
            domain = local_url.hostname.replace('admin.', '.')
        this.$cookies.remove('CloudFront-Policy', {
            path: '/videos',
            domain: domain
        })
        this.$cookies.remove('CloudFront-Key-Pair-Id', {
            path: '/videos',
            domain: domain
        })
        this.$cookies.remove('CloudFront-Signature', {
            path: '/videos',
            domain: domain
        })
        this.$apollo.queries.me.setOptions({
            fetchPolicy: 'cache-and-network'
        })
        this.setPollableQuery(this.$apollo.queries.me)
    },
    data: () => ({
        //
        term: '',
        menu: true,
    }),
    computed: {
        ...mapState('videoUploads', {videoUploads: 'list'}),
        ...mapState('networkError', {networkError: 'errorOccured'}),
        frontedUrl() {
            return process.env.VUE_APP_FRONTEND_URL ? `${process.env.VUE_APP_FRONTEND_URL}/home` : ''
        }
    },
    methods: {
        ...mapMutations('videoUploads', {setPollableQuery: 'setPollableQuery', removeUpload: 'removeUpload'}),
        ...mapMutations('permissions', {setPermissionList: 'setList'}),
        getVideoTypeForTranscodeJob(transcodeJob) {
            return transcodeJob.transcodeable.__typename === 'Short' ? this.$enums.VideoType.SHORT.key : this.$enums.VideoType.VOD.key
        }
    },
    apollo: {
        me: {
            query: me,
            fetchPolicy: "network-only",
            result({data}) {
                if (data && !data.me && this.$route.name !== 'Login') {
                    this.$router.push({name: 'Login'})
                }
                if (data && data.me) {
                    this.setPermissionList(data.me.permission_list)
                    if (data.me.awsTranscodeJobs.length === 0) {
                        this.$apollo.queries.me.stopPolling()
                    } else {
                        this.$apollo.queries.me.startPolling(10000)
                    }
                }
            },
        }
    }
};
</script>
<style>
@import "assets/fonts/saira.css";
</style>
