<template>
  <svg id="uuid-13f548b7-1685-4548-8fe0-41f00096c0ad" data-name="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <path d="M18.43,9.43v9.83c.92-1.03,1.98-1.92,3.14-2.63v-7.19h4.78l-6.35-6.36-6.36,6.36h4.79Z" fill="currentColor"/>
    <path d="M16.16,22.99c.4-1.02,.93-1.97,1.55-2.84-2.15-1.63-4.78-2.53-7.42-2.53h-.05v-4.78l-6.36,6.35,6.36,6.36v-4.79h.05c2.2,.05,4.28,.84,5.87,2.23Z" fill="currentColor"/>
    <path d="M29.76,17.62h-.05c-.15,0-.31-.01-.46-.01-5.62,0-10.33,4.29-10.82,9.95v.03s-.03,.01-.03,.01c-2.5,.88-3.81,3.64-2.93,6.14,.43,1.21,1.3,2.18,2.46,2.73,1.16,.55,2.47,.62,3.68,.2,2.49-.9,3.81-3.65,2.93-6.14-.48-1.37-1.55-2.44-2.93-2.93h-.04s0-.05,0-.05c.79-6.77,7.77-6.84,8.06-6.84h.05v4.79l6.44-6.31-6.36-6.35v4.79Z" fill="currentColor"/>
    <g>
      <path d="M36.18,19.19l-6.38-6.38v4.8h-.05c-.15,0-.31-.01-.46-.01-5.64,0-10.37,4.3-10.86,9.99v.03s-.03,.01-.03,.01c-2.51,.89-3.83,3.65-2.94,6.16,.43,1.21,1.3,2.19,2.47,2.74,1.16,.56,2.47,.63,3.69,.2,2.5-.9,3.82-3.66,2.94-6.16-.48-1.38-1.56-2.45-2.94-2.94h-.04s0-.05,0-.05c.8-6.79,7.79-6.87,8.09-6.87h.05v4.81l6.46-6.33Z" fill="currentColor"/>
      <path d="M17.7,20.15c-2.16-1.64-4.8-2.54-7.45-2.54h-.05v-4.8l-6.38,6.38,6.38,6.38v-4.81h.05c2.21,.05,4.3,.84,5.89,2.24,.4-1.02,.93-1.98,1.56-2.85Z" fill="currentColor"/>
      <path d="M18.43,19.25c.93-1.04,1.99-1.92,3.15-2.64v-7.22h4.8l-6.38-6.38-6.38,6.38h4.8v9.86Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SuiFields",
}
</script>
