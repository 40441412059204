import Vue from 'vue';
import padStart from "lodash/padStart";
import campaign_status from "@/assets/campaign_status";
import lodash_find from "lodash/find";

Object.defineProperty(Vue.prototype, `$timeToDuration`, {
    value: (time) => {
        let duration = Math.round(time)
        if (duration < 6000) {
            let minutes = padStart(`${Math.floor(duration / 60)}`, 2, '0')
            let seconds = padStart(`${duration % 60}`, 2, '0')
            return `${minutes}:${seconds}`
        } else {
            let hours = padStart(`${Math.floor(duration / 3600)}`, 2, '0')
            let minutes = padStart(`${Math.floor((duration%3600) / 60)}`, 2, '0')
            let seconds = padStart(`${duration % 60}`, 2, '0')
            return `${hours}:${minutes}:${seconds}`
        }
    }
})

Object.defineProperty(Vue.prototype, `$campaignStatus`, {
    value: (status) => {
        return lodash_find(campaign_status, (cs) => cs.value === status)?.text
    }
})
