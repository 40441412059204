<template>
  <v-row no-gutters class="fill-height">
    <v-col cols="12">
      <su-menu-list/>
    </v-col>
  </v-row>
</template>

<script>
import SuMenuList from '@/components/menu/MenuList';

export default {
  name: 'SuMenuDrawer',
  components: { SuMenuList },
};
</script>

<style scoped>
</style>
