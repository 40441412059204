import Vue from "vue";

Object.defineProperty(Vue.prototype,'$convertMoney', { value: (amount, negative = false) => {
        let cents = 0
        if (amount) {
            cents = parseInt(amount)
        }
        if (negative) {
            cents = amount * -1
        }
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(cents / 100);
    }});