import {mapMutations, mapState} from "vuex";
import lodash_debounce from "lodash/debounce";

export default {
    data() {
        return {
            debouncedSearchTerm: lodash_debounce(this.updateSearchTerm, 500),
        }
    },
    methods: {
        ...mapMutations({setGlobalTerm: 'search/setGlobalTerm'}),
        updateSearchTerm(e) {
            this.searchTerm=e
        }
    },
    computed: {
        ...mapState('search', {globalTerm: 'globalTerm'}),
        searchTerm: {
            get() {
                return this.globalTerm
            },
            set(newGlobalTerm) {
                this.setGlobalTerm(newGlobalTerm)
            },
        },
    }
};