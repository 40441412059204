import Vue from 'vue'
import lodash_map from 'lodash/map'
import lodash_foreach from 'lodash/forEach'
import lodash_merge from 'lodash/merge'
import lodash_defaults_deep from 'lodash/defaultsDeep'
import lodash_starts_with from 'lodash/startsWith'
import lodash_ceil from 'lodash/ceil'
import lodash_chunk from 'lodash/chunk'
import lodash_sum from 'lodash/sum'
import lodash_find from 'lodash/find'
import lodash_find_index from 'lodash/findIndex'
import lodash_filter from 'lodash/filter'
import lodash_concat from 'lodash/concat'
import lodash_order_by from 'lodash/orderBy'
import lodash_max_by from 'lodash/maxBy'
const lodash = {
    map: lodash_map,
    forEach: lodash_foreach,
    merge: lodash_merge,
    defaultsDeep: lodash_defaults_deep,
    startsWith: lodash_starts_with,
    ceil: lodash_ceil,
    chunk: lodash_chunk,
    sum: lodash_sum,
    find: lodash_find,
    findIndex: lodash_find_index,
    filter: lodash_filter,
    concat: lodash_concat,
    orderBy: lodash_order_by,
    maxBy: lodash_max_by,
}
Object.defineProperty(Vue.prototype, '$_', { value: lodash })