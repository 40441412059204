<template>
  <v-snackbar
      v-model="localSuccessAlertActive"
      color="success"
      :timeout="5000"
      top
  >
    {{ localSuccessAlertText }}
    <template v-slot:action>
      <v-btn
          icon
          @click="localSuccessAlertActive = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import SuccessAlertMixin from '../mixins/SuccessAlertMixin';


export default {
  name: 'SuSuccessAlert',
  mixins: [SuccessAlertMixin],
};
</script>

<style scoped>

</style>