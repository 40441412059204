import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import fields from '@/components/icons/Fields.vue'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            "sui-fields": {
                component: fields
            }
        }
    },
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#046172',
                secondary: '#1fb1bf',
                accent: '#00292e',
                anchor: '#00292e',
            },
        },
    },
});
