<template>
  <v-snackbar
      v-model="localErrorAlertActive"
      color="error"
      :timeout="-1"
      top
  >
    <ul>
      <li v-for="(errorText, idx) in localServerErrors" :key="idx">
        {{ errorText }}
      </li>
    </ul>
    <template v-slot:action>
      <v-btn
          icon
          @click="localErrorAlertActive = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import ErrorAlertMixin from '../mixins/ErrorAlertMixin';


export default {
  name: 'SuErrorAlert',
  mixins: [ErrorAlertMixin],
};
</script>

<style scoped>

</style>