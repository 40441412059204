<template>
    <v-card :to="route" :color="color" class="mx-6 my-3"
            dark v-show="!hidden">
        <v-card-title :class="{'text-h5': !minimized, 'subtitle-2': minimized, 'py-1': minimized}">
            <v-row align="center" no-gutters>
                <v-col>
                    {{ name }}
                </v-col>
                <v-col cols="auto">
                    <v-btn icon @click.stop.prevent="minimized=!minimized" color="white">
                        <v-icon>{{ minimized ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="subtitle-2" v-if="!minimized">
            Status: <b>{{ text }}</b>
        </v-card-text>
        <v-progress-linear v-if="showProgress" :value="progress" :buffer-value="0" stream color="white"/>
    </v-card>
</template>

<script>
export default {
    name: "SuVodTranscodeAlert",
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        progress: {
            type: Number,
            default: 0,
        },
        status: {
            type: String,
            default: '',
        },
        videoType: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            hidden: false,
            minimized: false,
        }
    },
    computed: {
        showProgress() {
            return this.status === this.$enums.AwsMediaConvertJobStatus.PROGRESSING.key || this.status === this.$enums.AwsMediaConvertJobStatus.SUBMITTED.key
        },
        color() {
            if (this.status === this.$enums.AwsMediaConvertJobStatus.COMPLETE.key) {
                return 'success'
            } else if (this.status === this.$enums.AwsMediaConvertJobStatus.ERROR.key || this.status === this.$enums.AwsMediaConvertJobStatus.CANCELED.key) {
                return 'error'
            } else {
                return 'primary'
            }
        },
        text() {
            if (this.status === this.$enums.AwsMediaConvertJobStatus.COMPLETE.key) {
                return 'Abgeschlossen'
            } else if (this.status === this.$enums.AwsMediaConvertJobStatus.ERROR.key || this.status === this.$enums.AwsMediaConvertJobStatus.CANCELED.key) {
                return 'Fehler'
            } else {
                return 'Transkodieren'
            }
        },
        route() {
            if (this.videoType === this.$enums.VideoType.VOD.key) {
                return {name: 'Vod', params: {vod_id: this.id}}
            } else {
                return {name: 'Short', params: {short_id: this.id}}
            }
        }
    }
}
</script>

<style scoped>

</style>