<template>
    <v-card :to="videoLink" color="primary" class="mx-6 my-3"
            dark>
        <v-card-title :class="{'text-h5': !minimized, 'subtitle-2': minimized, 'py-1': minimized}">
            <v-row align="center" no-gutters>
                <v-col>
                    {{ name }}
                </v-col>
                <v-col cols="auto">
                    <v-btn icon @click.stop.prevent="minimized=!minimized" color="white">
                        <v-icon>{{ minimized ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="subtitle-2" v-if="!minimized">Status: {{
                progress ? 'Hochladen' : 'Verarbeiten'
            }}
        </v-card-text>
        <v-progress-linear :value="progress * 100" :indeterminate="!progress" color="white"/>
    </v-card>
</template>

<script>
export default {
    name: "SuVodUploadAlert",
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        videoType: {
            type: String,
            required: true,
        },
        progress: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        videoLink() {
            return this.videoType === this.$enums.VideoType.VOD.key ? {name: 'Vod', params: {vod_id: this.id}} : {name: 'Short', params: {short_id: this.id}}
        }
    },
    data() {
        return {
            minimized: false,
        }
    },
}
</script>

<style scoped>

</style>