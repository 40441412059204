import { mapMutations, mapState } from 'vuex';


export default {
    computed: {
        ...mapState( 'successAlert', { successAlertActive: 'active', successAlertText: 'text' } ),
        localSuccessAlertActive:
            {
                get ()
                {
                    return this.successAlertActive;
                },
                set ( active )
                {
                    this.setSuccessAlertActive( active );
                },
            },
        localSuccessAlertText:
            {
                get ()
                {
                    return this.successAlertText;
                },
                set ( active )
                {
                    this.setSuccessAlertText( active );
                },
            },
    },
    methods: {
        ...mapMutations( {
            setSuccessAlertActive: 'successAlert/setActive',
            setSuccessAlertText: 'successAlert/setText',
        } ),
        success(text='Erfolgreich gespeichert!') {
            this.setSuccessAlertText(text)
            this.setSuccessAlertActive(true)
        }
    },
};