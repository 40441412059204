export default [
    {
        value: 'PENDING',
        text: 'Ausstehend',
    },
    {
        value: 'PROCESSING',
        text: 'In Arbeit',
    },
    {
        value: 'COMPLETED',
        text: 'Abgeschlossen',
    },
    {
        value: 'ERROR',
        text: 'Fehler',
    },
];
